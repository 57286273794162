import React, { Component } from "react";
import "@google/model-viewer";
import modelMiner from "./modelminer1.glb";

class ModelViewer extends Component {
  render() {
    return (
      <div
        style={{
          margin: "auto",
          width: "100%", // S'assure que le conteneur principal prend toute la largeur disponible
          maxWidth: "1200px", // Limite la largeur maximale pour grands écrans
          padding: "20px",
        }}
      >
        {/* Titre */}
        {/* <h1
          style={{
            textAlign: "center",
            marginBottom: "20px",
            color: "#333",
            fontFamily: "'Arial', sans-serif",
            fontSize: "2rem",
            //background: "gold",
            borderRadius: "8px",
          }}
        >
          Antminer KS5 Pro
        </h1>*/}

        {/* Section principale */}
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Empile les sections pour les petits écrans
            justifyContent: "space-between",
          }}
        >
          {/* Modèle 3D */}
          <div
            style={{
              width: "100%", // S'adapte à la largeur du conteneur
              marginBottom: "20px",
            }}
          >
            <model-viewer
              src={modelMiner}
              alt="A 3D model of a miner"
              ar
              auto-rotate
              camera-controls
              style={{
                width: "100%", // S'assure que le modèle prend toute la largeur
                height: "600px", // Hauteur du modèle ajustée
                borderRadius: "8px",
                //backgroundColor: "#fff", // Fond blanc pour le modèle
              }}
            ></model-viewer>
          </div>

          {/* Description et Caractéristiques */}
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Empile verticalement par défaut
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            {/* Description */}
            <div
              style={{
                flex: 1,
                padding: "20px",
                //backgroundColor: "#fff",
                borderRadius: "8px",
                // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h2
                style={{
                  marginBottom: "10px",
                  color: "black",
                  fontFamily: "'Arial', sans-serif",
                  fontSize: "1.5rem",
                }}
              >
                Description
              </h2>
              <p
                style={{
                  color: "black",
                  lineHeight: "1.6",
                  fontFamily: "'Arial', sans-serif",
                }}
              >
                Modèle Antminer KS5 Pro (21Th) de l'algorithme Bitmain
                miningKHeavyHash avec un taux de hachage maximal de 21Th/s pour
                une consommation d'énergie de 3150W.
              </p>
            </div>

            {/* Caractéristiques */}
            <div
              style={{
                flex: 1,
                padding: "20px",
                //backgroundColor: "#fff",
                borderRadius: "8px",
                //boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h2
                style={{
                  marginBottom: "10px",
                  color: "black",
                  fontFamily: "'Arial', sans-serif",
                  fontSize: "1.5rem",
                }}
              >
                Caractéristiques
              </h2>
              <ul
                style={{
                  color: "black",
                  lineHeight: "1.6",
                  fontFamily: "'Arial', sans-serif",
                  paddingLeft: "20px",
                }}
              >
                <li>Fabricant : Bitmain</li>
                <li>
                  Modèle : Antminer KS5 Pro (21Th) Aussi connu sous le nom de
                  Antminer KS5 Pro Kaspa Miner
                </li>
                <li>Libération : Mars 2024</li>
                <li>Dimensions : 195 x 290 x 430 mm</li>
                <li>Poids : 16100g</li>
                <li>Niveau de bruit : 75db</li>
                <li>Ventilateur(s) : 2</li>
                <li>Alimentation : 3150W</li>
                <li>Interface : Ethernet</li>
                <li>Température : 5 à 45 °C</li>
                <li>Humidité : 5 à 95 %</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModelViewer;
