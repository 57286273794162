import React, { useState } from "react";
import "./PCWithComponents.css";

import pcImage from "./images/pcImage-removebg-preview.png"; // Image principale du PC
import component1 from "./images/component1-removebg-preview.png"; // Exemple d'image de composant
import component2 from "./images/component2-removebg-preview.png";
import component3 from "./images/component3-removebg-preview.png";
import component4 from "./images/component4-removebg-preview.png";

const PCWithComponents = () => {
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="pc-container">
      {/* Image principale */}

      <img
        src={pcImage}
        alt="PC"
        className="pc-image"
        onClick={() => setShowTable(!showTable)}
        style={{ cursor: "pointer" }}
      />

      {/* Composants et lignes */}
      <div className="component component1">
        <div className="line line1"></div>
        <img src={component1} alt="CPU" className="component-image" />
        <div className="label">CPU</div>
      </div>

      <div className="component component2">
        <div className="line line2"></div>
        <img src={component2} alt="GPU" className="component-image" />
        <div className="label">GPU</div>
      </div>

      <div className="component component3">
        <div className="line line3"></div>
        <img src={component3} alt="RAM" className="component-image" />
        <div className="label">RAM</div>
      </div>

      <div className="component component4">
        <div className="line line4"></div>
        <img src={component4} alt="Storage" className="component-image" />
        <div className="label">Storage</div>
      </div>

      {showTable && (
        <div className="components-table">
          <h2>Liste des Composants</h2>
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Nom</th>
                <th>Caractéristiques</th>
              </tr>
            </thead>
            <tbody>{/* Tableau dynamique des composants */}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PCWithComponents;
