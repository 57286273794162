import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Conditions de Vente</h3>
          <p>
            <a
              href="/conditions-de-vente"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lire nos conditions de vente
            </a>
          </p>
        </div>

        <div className="footer-section">
          <h3>Mentions Légales</h3>
          <p>
            <a
              href="/mentions-legales"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lire nos mentions légales
            </a>
          </p>
        </div>
      </div>

      <div className="footer-bottom">
        &copy; 2024 AELGARDA | Tous droits réservés
      </div>
    </footer>
  );
};

export default Footer;
