import MoroccoFlag from "./flags/morocco.jpeg";
import CanadaFlag from "./flags/canada.jpeg";
import UAEFlag from "./flags/uaegd.jpeg";
import FranceFlag from "./flags/france.jpeg";

export const missingCountries = [
  { name: "Morocco", latLng: [31.7917, -7.0926] },
  { name: "Canada", latLng: [56.1304, -106.3468] },
  { name: "France", latLng: [46.6034, 1.8883] },
  { name: "Dubai", latLng: [25.276987, 55.296249] },
];
export const countries = {
  MA: 88, // Maroc
  CA: 33, // Canada
  AE: 79, // Émirats arabes unis
  FR: 100, // France
};

export const countryFlags = {
  MA: MoroccoFlag,
  CA: CanadaFlag,
  AE: UAEFlag,
  FR: FranceFlag,
};

export const countryNames = {
  MA: "Morocco",
  CA: "Canada",
  AE: "United Arab Emirates",
  FR: "France",
};

export const countryPower = {
  MA: "? MW Disponibles",
  CA: "Complet",
  AE: "12 MW Disponibles",
  FR: "? MW Disponibles",
};

export const countryService = {
  MA: "15%",
  CA: "15%",
  AE: "12,5%",
  FR: "15%",
};
export const countryPrice = {
  MA: "?",
  CA: "0.073 ",
  AE: "0.029 ",
  FR: "?",
};

export const colorScale = ["bleu", "bleu"];
