import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import React from "react";
import {
  colorScale,
  countries,
  countryFlags,
  countryNames,
  countryPower,
  countryPrice,
  countryService,
  missingCountries,
} from "./Countries";

function WorldMap() {
  return (
    <div style={{ margin: "auto", width: "1200px", height: "600px" }}>
      <VectorMap
        map={worldMill}
        containerStyle={{
          width: "700px",
          height: "600px",
        }}
        backgroundColor="rgba(0, 243, 255, 0.01)"
        series={{
          regions: [
            {
              scale: colorScale,
              values: countries,
              min: 0,
              max: 100,
            },
          ],
        }}
        markers={missingCountries.map((country) => ({
          latLng: country.latLng,
          name: country.name,
        }))}
        markerStyle={{
          initial: {
            fill: "gold",
          },
        }}
        onRegionTipShow={function (event, label, code) {
          if (countryFlags[code]) {
            label.html(`
              <div style="background-color: white; padding: 10px; width: 150px; color: black; text-align: center; font-family: Arial, sans-serif; border: 2px solid #ccc; border-radius: 8px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
                <div style="text-align: center;">
                  <img src="${countryFlags[code]}" alt="${countryNames[code]} flag" style="width: 40px; height: auto; margin-bottom: 10px;" />
                </div>
                <p style="margin: 0; font-size: 14px; font-weight: bold;">${countryNames[code]}</p>
                <p style="margin: 5px 0;">
                  <span style="display: flex; align-items: center;">
                    <span style="font-size: 16px; margin-right: 5px;">⚡</span>
                    <b>Puissance:</b> ${countryPower[code]}
                  </span>
                  <span style="display: flex; align-items: center; margin-top: 5px;">
                    <span style="font-size: 16px; margin-right: 5px;">💰</span>
                    <b>Prix:</b> ${countryPrice[code]} €/kWh
                  </span>
                  <span style="display: flex; align-items: center; margin-top: 5px;">
                    <span style="font-size: 16px; margin-right: 5px;">🛠️</span>
                    <b>Frais de service:</b> ${countryService[code]}
                  </span>
                </p>
              </div>
            `);
          }
        }}
      />
      <style>
        {`
          .jvectormap-marker {
            position: relative;
            width: 10px;
            height: 10px;
            background-color: gold;
            border-radius: 50%;
          }

          .jvectormap-marker::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            background-color: gold;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            animation: pulse 2s infinite ease-in-out;
          }

          @keyframes pulse {
            0%, 100% {
              transform: translate(-50%, -50%) scale(0.5);
              opacity: 0.7;
            }
            50% {
              transform: translate(-50%, -50%) scale(1.5);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}

export default WorldMap;
