import React from "react";

const ContactComponent = () => {
  const phoneNumber = "+33757842723"; // Votre numéro WhatsApp Business
  const message = "Bonjour, je suis intéressé par vos services."; // Message pré-rempli

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div
      style={{
        backgroundColor: "#f4f4f4",
        padding: "20px",
        borderRadius: "8px",
        textAlign: "center",
        maxWidth: "600px",
        margin: "auto",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2 style={{ color: "#333", marginBottom: "20px" }}>Bienvenue!</h2>
      <p style={{ color: "#555", marginBottom: "30px" }}>
        Nous sommes ravis de vous avoir ici. Si vous avez des questions ou si
        vous souhaitez en savoir plus sur nos services, n'hésitez pas à nous
        contacter. Nous sommes là pour vous aider !
      </p>
      <button
        onClick={handleWhatsAppClick}
        style={{
          backgroundColor: "#25D366", // Couleur WhatsApp
          color: "#fff",
          fontSize: "18px",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Contactez-nous sur WhatsApp
      </button>
    </div>
  );
};

export default ContactComponent;
