import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPercentage,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const Avantages = () => {
  const avantages = [
    {
      icon: faHome,
      title: "Pas de loyer",
      description:
        "La dépense énergétique est directement prélevée sur votre production",
    },
    {
      icon: faPercentage,
      title: "15% de frais de service",
      description:
        "Prélevées sur les bénéfices nets selon le pays d'hébergement",
    },
    {
      icon: faLeaf,
      title: "100% renouvelable",
      description: "Énergie verte",
    },
  ];

  // Define continuous animation variants
  const bounceVariants = {
    animate: {
      scale: [1, 1.1, 1],
      rotate: [0, 10, -10, 0],
      transition: {
        scale: {
          duration: 1,
          repeat: Infinity,
          repeatType: "loop",
          ease: "easeInOut",
        },
        rotate: {
          duration: 1.5,
          repeat: Infinity,
          repeatType: "loop",
          ease: "easeInOut",
        },
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        padding: "20px",
        //background: "white",
      }}
    >
      {avantages.map((avantage, index) => (
        <motion.div
          key={index}
          variants={bounceVariants}
          animate="animate"
          style={{ textAlign: "center", maxWidth: "200px" }}
        >
          <FontAwesomeIcon
            icon={avantage.icon}
            size="3x"
            style={{ color: "gold" }}
          />
          <h3 style={{ fontWeight: "bold", margin: "10px 0", color: "white" }}>
            {avantage.title}
          </h3>
          <p style={{ color: "white" }}>{avantage.description}</p>
        </motion.div>
      ))}
    </div>
  );
};

export default Avantages;
