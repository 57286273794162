// Header.js
import React from "react";

const Header = ({ sections }) => {
  return (
    <header style={{ backgroundColor: "#3e4d0d", padding: "10px 20px" }}>
      <nav>
        <ul
          style={{
            display: "flex",
            justifyContent: "space-around",
            listStyle: "none",
            margin: "auto",
            padding: 0,
          }}
        >
          {sections.map((section, index) => (
            <li key={index}>
              <a
                href={`#${section.id}`}
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  fontFamily: "Roboto",
                }}
              >
                {section.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
