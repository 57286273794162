import React from "react";
import Header from "./Header";
import ModelViewer from "./ModelViewer";
import PCWithComponents from "./PCWithComponents";
import WorldMap from "./WorldMap";
import Avantages from "./avantages";
import ContactComponent from "./ContactComponenet";
import LogoCarousel from "./LogoCarousel";
import Footer from "./Footer";
import "./App.css";

const App = () => {
  const sections = [
    { id: "model-viewer", name: "Antminer KS5 Pro" },
    { id: "pc-components", name: "PC & Components" },
    { id: "world-map", name: "Nos locaux" },
    { id: "avantages", name: "Avantages" },
    { id: "contact", name: "Contact" },
    { id: "logos", name: "Nos partenaires" },
  ];

  return (
    <div className="app">
      <Header sections={sections} />
      <main>
        <section id="model-viewer" className="hero">
          <ModelViewer />
        </section>

        <section id="pc-components" className="features">
          <PCWithComponents />
        </section>

        <section id="world-map" className="world-map">
          <WorldMap />
        </section>

        <section id="avantages" className="advantages">
          <Avantages />
        </section>

        <section id="contact" className="contact">
          <ContactComponent />
        </section>

        <section id="logos" className="logo-carousel">
          <LogoCarousel />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default App;
