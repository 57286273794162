import React from "react";
import "./LogoCarousel.css";

import logo1 from "./images/logo1.png";
import logo2 from "./images/logo2.png";
import logo3 from "./images/logo3.png";
import logo4 from "./images/logo4.png";

const LogoCarousel = () => {
  return (
    <div className="carousel-container">
      <div className="carousel">
        <img src={logo1} alt="Logo 1" className="carousel-logo" />
        <img src={logo2} alt="Logo 2" className="carousel-logo" />
        <img src={logo3} alt="Logo 3" className="carousel-logo" />
        <img src={logo4} alt="Logo 4" className="carousel-logo" />
      </div>
    </div>
  );
};

export default LogoCarousel;
